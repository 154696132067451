function setupQuestions(){
	processQuestions();
	sectionResize();
}

	function processQuestions() {

		// set the first question to show
		$('.question').first().addClass('active');
		$('.question').first().attr('data-shown', "1");
		$('.back-arrow a').hide();
		//set the variable holding question number as well as print it to the top bar
		var n=1;
		$('.js-number').text(n);
		//set the total amount of questions in the top bar
		$('.js-total').text( $('.question').length );
		var answers = [];

		//handle answer click
		$(document).on('click', '.questions .answer', function(e){

			e.preventDefault();

			if( $('.questions').hasClass('type-2') ) {
				if(!$('.type-2 .question.active input:radio').is(":checked")){
					return false;
				}
			}

			if( $(this).hasClass('next') ){
				var radioValue = $('.question.active').find('input:checked').attr('value');
				$('.next').attr('data-answer', radioValue);
			}

			//set question at top of page
			if(n < $('.question').length ) {
				n++;
				$('.js-number').text(n);
			}


			// get and set the answer
			var currentQuestion = $('.questions .question.active');
			var answer = $(this).attr('data-answer');
			var questionCount = $('.questions .question').length;


			var currentQuestionIndex = $('.question').index($('.question.active')) + 1;
			var percentComplete = Math.round((100/questionCount)*currentQuestionIndex);
			$('.questions .percentage').text(percentComplete);

			$('.back-arrow a').show();

			$(currentQuestion).attr('data-answer', answer);
			if($('.question.active').nextAll('[data-answer=""]').length){
				var nextQ = $('.question.active').removeClass('active').nextAll('[data-answer=""]').first();
				$(nextQ).addClass('active');
				$(nextQ).attr('data-shown', "1");
			}else{
				$(currentQuestion).hide();
				$('.buttons').hide();
				$('.loader').show();

				$('.questions .question').each(function (){
					var data = {
						"id":$(this).attr('data-id'),
						"answer":$(this).attr('data-answer')
					}
					answers.push(data);
				});

				var str = JSON.stringify(answers);
				$("#answers").val(str);
				$("#answers").parents("form").submit();

			}
		});



		$(document).on('click', '.back-arrow a', function(e){

			e.preventDefault();

						//set question at top of page
			n--;
			$('.js-number').text(n);

			var previousQuestion = $('.question.active').prevAll('[data-shown="1"]').first();

			var questionCount = $('.questions .question').length;
			var currentQuestionIndex = $(".question").index($('.question.active')) - 1;
			var percentComplete = Math.round((100/questionCount)*currentQuestionIndex);
			$('.percentage').text(percentComplete);
			$('.bar .inner').css({
				'width': percentComplete + '%'
			});

			if($('.question.active').prevAll('[data-shown="1"]').length){
				$('.question.active').attr('data-shown', "");
				$('.question.active').attr('data-answer', "");
				$('.question.active').removeClass('active').prevAll('[data-shown="1"]').first().addClass('active');
			};

			if(percentComplete==0)
			{
				$(this).hide();
			}

		});
	}


	function sectionResize() {

		function resize() {
			var windowHeight 	= $(window).height();
			var heightToSet = windowHeight - 89;

			$('.section-questions').height(heightToSet);
		}

		resize();

		$(window).resize(function () {
			resize();
		});

	}
