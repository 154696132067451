function setupHome(){

  function validateEmail(sEmail) {
      var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
      if (filter.test(sEmail)) {
          return true;
      }
      else {
          return false;
      }
  }




$('input, select, textarea').on('input', function(){
  if( $(this).val() ){
    $(this).addClass('validated');
    $(this).removeClass('error-required');

  } else {
    $(this).removeClass('validated');
  }
})

$( '.modal button' ).click( function( event ) {

        var formStep = $(this).attr('data-step');

        if( formStep == 1 ){
          event.preventDefault();
        }

        //validate fields
        var fail = false;
        var fail_log = '';
        var sEmail = $('input.email').val();
        $( '.step-' + formStep ).find( 'select, textarea, input' ).each(function(){
            if( ! $( this ).prop( 'required' )){

            } else {
                if ( ! $( this ).val() ) {
                    fail = true;
                    // name = $( this ).attr( 'name' );
                    // fail_log += name + " is required \n";
                    $(this).addClass('error-required');
                } else {
                  $(this).removeClass('error-required');
                }
            }

            if (validateEmail(sEmail)) {
                //console.log('Email is valid');
                $('input.email').removeClass('error-required');
                $('.email-msg').removeClass('show-msg');
            }
            else {
                //console.log('Invalid Email Address');
                $('input.email').addClass('error-required');
                $('input.email').removeClass('validated');
                $('.email-msg').addClass('show-msg');
                fail = true;

            }
        });

        //submit if fail never got set to true
        if ( ! fail && validateEmail(sEmail) == true ) {
            //proceed to step 2
            if( formStep == 1 ){
              $('.step-1').fadeOut('fast', function(){
                $('.step-2').fadeIn('fast');
              });
              $('.step-count span').text('2');
            }

            $('.show-msg').removeClass('show-msg');
        } else {
            //alert( fail_log );
            $('.field-msg').addClass('show-msg');
            return false;
        }

});


}
