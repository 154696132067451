function setupResults() {
    sectionResize();
    highCharts();
}

    function sectionResize() {

        function resize() {
            var windowHeight    = $(window).height(),
                mainHeight      = $('.dynamic-section').height(),
                bottomHeight    = $('.section-social-shares').height(),
                headerHeight    = $('.site-header').height(),
                footerHeight    = $('.site-footer').height();

            var heightToSet = windowHeight - headerHeight - footerHeight - bottomHeight;

            if (mainHeight <= heightToSet) {
                $('.dynamic-section').height(heightToSet);
            } else {
                $('.dynamic-section').height('auto');
            }
        }

        resize();

        $(window).resize(function () {
            resize();
        });

    }

    $('.change-email').click(function(){
        $('#changeemail-popup').addClass('show');
    });

    $('#changeemail-popup .overlay, #changeemail-popup .circle').click(function(){
        $('#changeemail-popup').removeClass('show');
    });

function highCharts(){

    $('.graph').highcharts({

        chart: {
            type: 'solidgauge'
        },

        title: {
            text:''
        },

        credits: {
            enabled: false
        },

        pane: {
            startAngle: 0,
            endAngle: 360,
            background: [{
                outerRadius: '112%',
                innerRadius: '88%',
                backgroundColor: '#efefef',
                borderWidth: 0
            }]
        },

        yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: []
        },

        plotOptions: {
            solidgauge: {
                borderWidth: '15px',
                dataLabels: {
                    enabled: false
                },
                linecap: 'round',
                stickyTracking: false
            }
        },

        series: [{
            borderColor: colorCode(data),
            data: [{
                color: colorCode(data),
                radius: '100%',
                innerRadius: '100%',
                y: data
            }]
        }]

    });

}